import {
  subYears,
  endOfISOWeekYear,
  startOfISOWeekYear,
  startOfMonth,
  addYears,
  startOfTomorrow,
  startOfToday,
  endOfToday,
  endOfYear,
  startOfYear,
  max,
} from 'date-fns'
import { getStartOfClosestWorkingDay } from './workdays'

const MAX_CHILD_AGE = 100
const MIN_EMPLOYEE_AGE = 16
const MAX_EMPLOYEE_AGE = 100

export const getChildAgeSelectionRules = () => ({
  minDate: subYears(new Date(), MAX_CHILD_AGE),
  maxDate: new Date(),
  defaultDate: new Date(),
})

export const getEmployeeAgeSelectionRules = () => ({
  minDate: subYears(new Date(), MAX_EMPLOYEE_AGE),
  maxDate: subYears(new Date(), MIN_EMPLOYEE_AGE),
  defaultDate: subYears(new Date(), MIN_EMPLOYEE_AGE),
})

export const getHireDateSelectionRules = birthDateParam => {
  if (!birthDateParam) {
    return {
      minDate: null,
      maxDate: null,
      defaultDate: new Date(),
    }
  }

  const birthDate = new Date(birthDateParam)
  const minDate = addYears(birthDate, MIN_EMPLOYEE_AGE)
  const maxDate = addYears(birthDate, MAX_EMPLOYEE_AGE)
  const defaultDate = max(minDate, new Date())

  return {
    minDate,
    maxDate,
    defaultDate,
  }
}

export const getHolidayRequestDateSelectionRules = ({
  employee,
  holidayRequest,
  requestType,
}) => {
  const startOfPreviusYear = startOfYear(subYears(new Date(), 1))
  const endOfNextYear = endOfYear(addYears(new Date(), 1))
  const startOfCurrentMonth = startOfMonth(new Date())

  if (requestType === 'SICK_LEAVE') {
    return {
      minDate: employee.isAdmin ? startOfPreviusYear : startOfCurrentMonth,
      maxDate: employee.isAdmin ? endOfNextYear : endOfToday(),
      startDate: holidayRequest
        ? holidayRequest.startDate
        : getStartOfClosestWorkingDay(startOfToday()),
      endDate: holidayRequest
        ? holidayRequest.endDate
        : getStartOfClosestWorkingDay(startOfToday()),
    }
  }

  if (requestType === 'VACATION') {
    return {
      minDate: employee.isAdmin ? startOfPreviusYear : startOfCurrentMonth,
      maxDate: endOfNextYear,
      startDate: holidayRequest
        ? holidayRequest.startDate
        : getStartOfClosestWorkingDay(startOfToday()),
      endDate: holidayRequest
        ? holidayRequest.endDate
        : getStartOfClosestWorkingDay(startOfToday()),
    }
  }

  if (requestType === 'PLANNED') {
    return {
      minDate: employee.isAdmin ? startOfPreviusYear : startOfTomorrow(),
      maxDate: endOfNextYear,
      startDate: holidayRequest
        ? holidayRequest.startDate
        : getStartOfClosestWorkingDay(startOfToday()),
      endDate: holidayRequest
        ? holidayRequest.endDate
        : getStartOfClosestWorkingDay(startOfToday()),
    }
  }

  if (requestType === 'COMPANY_HOLIDAY') {
    return {
      minDate: startOfToday(),
      maxDate: endOfYear(new Date()),
      startDate: startOfToday(),
      endDate: startOfToday(),
    }
  }

  return {
    minDate: startOfISOWeekYear(new Date()),
    maxDate: endOfISOWeekYear(new Date()),
    startDate: startOfToday(),
    endDate: startOfToday(),
  }
}
