import React from 'react'
import { ButtonGroup } from '../styles/Button'
import { Spacer } from '../styles/Spacer'

import { EmployeeType } from '../../common/prop-types'

import EmployeeActivate from './EmployeeActivate'
import EmployeeDeactivate from './EmployeeDeactivate'
import EmployeeDelete from './EmployeeDelete'
import EmployeeReinvite from './EmployeeReinvite'

const EmployeeControl = ({ employee }) => (
  <ButtonGroup>
    {employee.active && (
      <Spacer mr={1}>
        <EmployeeReinvite employee={employee} />
      </Spacer>
    )}
    {employee.active ? (
      <EmployeeDeactivate employee={employee} />
    ) : (
      <EmployeeActivate employee={employee} />
    )}

    <Spacer ml={1}>
      <EmployeeDelete employee={employee} />
    </Spacer>
  </ButtonGroup>
)

EmployeeControl.propTypes = {
  employee: EmployeeType,
}

export default EmployeeControl
