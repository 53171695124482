import React from 'react'
import PropTypes from 'prop-types'
import {
  addDays,
  subDays,
  subMonths,
  addMonths,
  startOfDay,
  startOfMonth,
  subYears,
  addYears,
  endOfYear,
  isAfter,
  isBefore,
} from 'date-fns'

import { FunctionType, DateType, IntlType } from '../../common/prop-types'
import { Tick, ActualDate, Container } from './style'

const MAX_SELECTABLE_DATE = endOfYear(addYears(new Date(), 1))
const MIN_SELECTABLE_DATE = endOfYear(subYears(new Date(), 2))

export function isValidDate(date) {
  const dateAsDate = date instanceof Date ? date : new Date(date)
  return (
    isAfter(dateAsDate, MIN_SELECTABLE_DATE) &&
    isBefore(dateAsDate, MAX_SELECTABLE_DATE)
  )
}

const getPreviousDate = (step, date) => {
  let previousDate
  if (step === 'day') {
    previousDate = startOfDay(subDays(date, 1))
  }

  if (step === 'month') {
    previousDate = startOfMonth(subMonths(date, 1))
  }

  if (step === 'year') {
    previousDate = startOfMonth(subYears(date, 1))
  }

  return previousDate
}

const getNextDate = (step, date) => {
  let nextDate
  if (step === 'day') {
    nextDate = startOfDay(addDays(date, 1))
  }

  if (step === 'month') {
    nextDate = startOfMonth(addMonths(date, 1))
  }

  if (step === 'year') {
    nextDate = startOfMonth(addYears(date, 1))
  }

  return nextDate
}

function getFormattedDate(step, date, intl) {
  let formattedDate
  if (step === 'day') {
    formattedDate = intl.formatDate(date, {
      month: 'long',
      day: 'numeric',
    })
  }

  if (step === 'month') {
    formattedDate = intl.formatDate(date, {
      month: 'long',
      year: 'numeric',
    })
  }

  if (step === 'year') {
    formattedDate = intl.formatDate(date, {
      year: 'numeric',
    })
  }

  return formattedDate
}

export default function SimpleDateSelector({ step, date, intl, onChange }) {
  function jumpToPreviousDate() {
    const newDate = getPreviousDate(step, date)

    if (isAfter(newDate, MIN_SELECTABLE_DATE)) {
      onChange({ date: newDate })
    }
  }

  function jumpToNextDate() {
    const newDate = getNextDate(step, date)
    if (isBefore(newDate, MAX_SELECTABLE_DATE)) {
      onChange({ date: newDate })
    }
  }

  const formattedDate = getFormattedDate(step, date, intl)

  return (
    <Container>
      <Tick
        onClick={jumpToPreviousDate}
        id="backTick"
        src="/assets/img/step-left.svg"
        alt="previous"
      />
      <ActualDate>{formattedDate}</ActualDate>
      <Tick
        onClick={jumpToNextDate}
        id="nextTick"
        src="/assets/img/step-right.svg"
        alt="next"
      />
    </Container>
  )
}

SimpleDateSelector.propTypes = {
  onChange: FunctionType.isRequired,
  step: PropTypes.oneOf(['day', 'month', 'year']),
  date: DateType.isRequired,
  intl: IntlType.isRequired,
}

SimpleDateSelector.defaultProps = {
  step: 'day',
}
