import { graphql } from '@apollo/client/react/hoc'
import { injectIntl } from 'react-intl'
import { compose } from 'redux'

import EmployeeReinvite from './EmployeeReinvite'
import RESEND_EMPLOYEE_INVITATION from '../../../graphql/mutations/resendEmployeeInvitation'

export default compose(
  injectIntl,
  graphql(RESEND_EMPLOYEE_INVITATION)
)(EmployeeReinvite)
