import { format } from 'date-fns'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useHistory,
} from 'react-router-dom'
import { EmployeeType } from '../../../../../common/prop-types'
import RequestHolidayButton from '../../../../../components/RequestHolidayButton'
import SimpleDateSelector from '../../../../../components/SimpleDateSelector'
import { Text } from '../../../../../components/styles'
import { ResponsiveCurrentMonthButton } from '../../../../../components/styles/Button'
import ViewSwith from '../../../../../components/ViewSwitch'
import { RightControls } from '../../../../../elements'
import { selectDate } from '../../../../../redux/profile/profile.reducer'
import { selectSelectedDate } from '../../../../../redux/profile/profile.selector'
import EmployeeCalendar from '../../EmployeeCalendar'
import EmployeeHolidaysModule from '../../EmployeeHolidaysModule'
import EmployeeTimeOffHeader from '../../EmployeeTimeOffHeader'
import { Container, EmployeeTimeOffContainer } from './style'
import { isValidDate } from '../../../../../components/SimpleDateSelector/SimpleDateSelector'

export default function EmployeeTimeOffView({
  viewMode,
  viewModes,
  onViewModeChange,
  employee,
  match,
}) {
  const dispatch = useDispatch()
  const history = useHistory()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  let queryDate = query.get('date')

  const currentMonth = format(new Date(), 'yyyy-MM')

  function addDateToUrl(date) {
    const formattedDate = format(date, 'yyyy-MM')
    const newQuery = new URLSearchParams(search)
    newQuery.set('date', formattedDate)
    history.replace({ search: newQuery.toString() })
  }

  useEffect(() => {
    if (!queryDate || !isValidDate(queryDate)) {
      addDateToUrl(new Date())
      queryDate = currentMonth
    }

    dispatch(selectDate(new Date(queryDate)))
  }, [queryDate, dispatch])

  const selectedDate = useSelector(selectSelectedDate)

  function handleDateSelectorClick(date) {
    dispatch(selectDate(date))
    addDateToUrl(date)
  }

  function handleCurrentMonthClick() {
    const now = new Date()
    dispatch(selectDate(now))
    addDateToUrl(now)
  }

  return (
    <Container>
      <EmployeeTimeOffContainer>
        <EmployeeTimeOffHeader employee={employee} />

        <RightControls>
          <div className="control">
            <ViewSwith
              viewMode={viewMode}
              viewModes={viewModes}
              onViewModeChange={onViewModeChange}
            />
          </div>
          <div className="control">
            <RequestHolidayButton employee={employee} />
          </div>
        </RightControls>
      </EmployeeTimeOffContainer>

      <RightControls>
        <div className="control">
          <SimpleDateSelector
            onChange={({ date }) => handleDateSelectorClick(date)}
            step={viewMode === 'calendar' ? 'year' : 'month'}
            date={selectedDate}
          />
        </div>
        <div className="control">
          <ResponsiveCurrentMonthButton
            secondary
            bordered
            narrow
            color="gray"
            onClick={handleCurrentMonthClick}
          >
            <Text uppercase>
              <FormattedMessage
                id={
                  viewMode === 'calendar'
                    ? 'Button.CurrentYear'
                    : 'Button.CurrentMonth'
                }
              />
            </Text>
          </ResponsiveCurrentMonthButton>
        </div>
      </RightControls>

      <Switch>
        <Route
          path={`${match.path}/calendar`}
          name="employee-calendar"
          component={() => (
            <EmployeeCalendar
              date={selectedDate}
              holidayRequests={employee.holidayRequests}
            />
          )}
        />
        <Route
          path={`${match.path}/list`}
          name="employee-holidays"
          component={() => <EmployeeHolidaysModule employeeId={employee.id} />}
        />
        <Redirect
          to={{
            pathname: `${match.path}/calendar`,
          }}
        />
      </Switch>
    </Container>
  )
}

EmployeeTimeOffView.propTypes = {
  viewMode: PropTypes.string.isRequired,
  viewModes: PropTypes.shape({
    calendar: PropTypes.string,
    list: PropTypes.string,
  }),
  onViewModeChange: PropTypes.func.isRequired,
  employee: EmployeeType.isRequired,
  match: PropTypes.shape({ path: PropTypes.string.isRequired }),
}

EmployeeTimeOffView.displayName = 'EmployeeTimeOffView'
